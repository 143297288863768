import { FC } from 'react';
import { Link } from 'react-router-dom';

const LocalHome: FC = () => {

  return <Link to='/local/home'>homeへ</Link> 
}

export default LocalHome;

